import { Route } from '@angular/router';
import { EntityTypeEnum } from '@libs/core';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@libs/home').then((m) => m.homeRoutes)
  },
  {
    path: 'auth',
    loadChildren: () => import('@libs/authentication').then((m) => m.authenticationRoutes)
  },
  {
    path: 'login',
    redirectTo: 'auth',
  },
  {
    path: 'account',
    loadChildren: () => import('@libs/account').then((m) => m.accountRoutes)
  },
  {
    path: EntityTypeEnum.Tournaments,
    pathMatch: 'full',
    loadChildren: () => import('@libs/tournaments').then((m) => m.tournamentsRoutes)
  },
  {
    path: EntityTypeEnum.Tournaments,
    loadChildren: () => import('@libs/tournament').then((m) => m.tournamentRoutes)
  },
];
