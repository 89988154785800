import { Component, OnInit } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MessageInterface, MessageService } from '../../core';

@Component({
  standalone: true,
  selector: 'lib-message',
  templateUrl: './message.component.html',
  imports: [
    NgForOf,
    NgClass,
    TranslateModule
  ],
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  public messages: MessageInterface[] = [];
  private timeoutRef: number | null = null;

  constructor(
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.messageService.messages$.subscribe(
      (message) =>  {
        this.messages.push(message);
        if (!this.timeoutRef) {
          this.timeoutRef = window.setTimeout(
            () => this.removeOldestMessage(),
            message.style === 'error' ? 6000 : 3000
            );
        }
      }
    );
  }

  private removeOldestMessage() {
    this.timeoutRef = null;
    this.messages.pop();
    if (this.messages.length) {
      this.timeoutRef = window.setTimeout(
        () => this.removeOldestMessage(),
        this.messages[0].style === 'error' ? 6000 : 3000
      );
    }

  }

}
