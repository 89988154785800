import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StorageService } from '@libs/core';
import { MessageComponent } from '@libs/components/message';
import { EntityEditComponent } from '@libs/entity';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, TranslateModule, MessageComponent, EntityEditComponent],
  providers: [],
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    public translate: TranslateService,
  ) {
    translate.addLangs(['en', 'nl', 'de', 'pl','fr','it','pt']);
    translate.setDefaultLang('en');

    const langToUse = StorageService.getLang() || translate.getBrowserLang() || translate.defaultLang;

    if (translate.getLangs().includes(langToUse)) {
      translate.use(langToUse);
    } else {
      translate.use(translate.defaultLang);
    }

    StorageService.storeLang(translate.currentLang);
  }
}
